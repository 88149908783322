import React from 'react'
import { Price } from '~common/components/product/Price'
import { Button } from '~ui/Button'
import {
  OrderLine,
  CurrencyCode,
  Agreement,
  OrderLineCustomFieldsInput,
} from '~common/generated/storefront-graphql'
import { Link, TextButton } from '~ui'
import clsx from 'clsx'
import { XCircleIcon } from '@heroicons/react/24/solid'

import { useFetcher } from '@remix-run/react'
import { extractSubscriptionsFromBreakdown } from '~common/utils/subscriptionUtils'
import { format } from 'date-fns'
import { DisplayType, EditType } from './types'
import Checkbox from '~ui/Checkbox'
import { AgreementModal } from './AgreementModal'
import { SubscriptionRow } from './SubscriptionRow'
import { getLineDateRange } from '~common/utils/cartUtils'
import { CLAZZ_FACET_CODE, DISABLE_EDIT_FV_CODE, SUBSCRIPTIONS } from '../product/constants'

const hasSubscription = (orderLine: OrderLine) => {
  return orderLine.productVariant.isSubscription
}

const hasGroup = (orderLine: OrderLine) => {
  return orderLine.isClass
}

export const adminEditable: EditType[] = [
  'groups',
  'amounts',
  'interval',
  'dates',
  'remove',
]
export const consumerEditable: EditType[] = [
  'groups',
  'interval',
  'dates',
  'remove',
]
export const notEditable: EditType[] = []

const Subscriptions: React.FC<{
  line: OrderLine
  priceIncludingTax?: boolean
  currencyCode?: CurrencyCode
  subscriptionEditUrl?: string
  groupEditUrl?: string
  editable?: EditType[]
  agreementApproval?: boolean
  displayDetails?: boolean
  defaultToOpen?: boolean
  onSignAgreement?: (lineId: string, checked: boolean) => void
  onSubscriptionEdit?: (
    type: 'recurring' | 'registration',
    editType: EditType,
    displayType: DisplayType,
  ) => void
  onAdjustOrderLine?: (
    lineId: string,
    quantity: number,
    customFields?: OrderLineCustomFieldsInput,
  ) => void
}> = ({
  line,
  priceIncludingTax,
  currencyCode = CurrencyCode.Usd,
  subscriptionEditUrl,
  editable = [],
  agreementApproval,
  displayDetails = true,
  defaultToOpen = true,
  groupEditUrl,
  onSignAgreement,
  onSubscriptionEdit,
  onAdjustOrderLine,
}) => {
  // How can you associate an agreement that needs to be checked to associate it in the first place?
  const associatedAgreement = line.agreement
  const signedAgreement = line.customFields?.agreement
  const [agreeTerms, setAgreeTerms] = React.useState<boolean>(!!signedAgreement)
  const [modalOpen, setModalOpen] = React.useState<boolean>(false)
  const linePrice = priceIncludingTax ? line.linePriceWithTax : line.linePrice
  // const [recurringFee, initialFee] = extractSubscriptions(line)
  const [recurringFee, initialFee] = extractSubscriptionsFromBreakdown(line)
  const toggleModal = React.useCallback((open: boolean) => {
    setModalOpen(open)
  }, [])
  const handleCheckAgreement = React.useCallback(
    (checked: boolean) => {
      setAgreeTerms(checked)
      if (onSignAgreement) {
        onSignAgreement(line.id, checked)
      }
      if (!associatedAgreement) {
        console.log('No agreement present')
      } else {
        // this can only be done if the order is in AddingItems state
        // if (onAdjustOrderLine) {
        //   onAdjustOrderLine(line.id, line.quantity, {
        //     agreementId: checked ? associatedAgreement.id : null,
        //   })
        // }
      }
    },
    [associatedAgreement],
  )

  return (
    <>
      {associatedAgreement ? (
        <AgreementModal
          isOpen={modalOpen}
          setOpen={setModalOpen}
          agreements={[associatedAgreement] as Agreement[]}
        />
      ) : null}
      <div className="mt-0.5">
        {initialFee && displayDetails ? (
          <SubscriptionRow
            key={`${initialFee.name}`}
            subscription={initialFee}
            line={line}
            priceIncludingTax={priceIncludingTax}
            currencyCode={currencyCode}
            type="registration"
            displayEdit={!recurringFee}
            defaultToOpen={defaultToOpen}
            editable={editable}
            onSubscriptionEdit={
              onSubscriptionEdit
                ? (
                    key: 'registration' | 'recurring',
                    editType: EditType,
                    displayType: DisplayType,
                  ) => onSubscriptionEdit('registration', editType, displayType)
                : undefined
            }
          >
            {/*line.customFields?.recurringRegistrationDiscount ? (
              <>
                <div className="mt-0.25 text-xs text-gray-500">
                  {line.customFields?.recurringRegistrationDiscount ? (
                    <p>
                      <span className="font-semibold">
                        Recurring discount of{' '}
                      </span>
                      <Price
                        priceWithTax={
                          line.customFields?.recurringRegistrationDiscount
                        }
                        currencyCode={currencyCode}
                      />
                    </p>
                  ) : null}
                </div>
              </>
            ) : null*/}
          </SubscriptionRow>
        ) : null}
        {recurringFee && displayDetails ? (
          <SubscriptionRow
            key={`${recurringFee.name}`}
            subscription={recurringFee}
            line={line}
            priceIncludingTax={priceIncludingTax}
            currencyCode={currencyCode}
            type="recurring"
            displayEdit
            defaultToOpen={defaultToOpen}
            editable={editable}
            onSubscriptionEdit={
              onSubscriptionEdit
                ? (
                    key: 'registration' | 'recurring',
                    editType: EditType,
                    displayType: DisplayType,
                  ) => onSubscriptionEdit('recurring', editType, displayType)
                : undefined
            }
          >
            {/*line.customFields?.recurringDiscount ? (
              <div className="mt-0.25 text-xs text-gray-500">
                <p>
                  <span className="font-semibold">Recurring discount of </span>
                  <Price
                    priceWithTax={line.customFields?.recurringDiscount}
                    currencyCode={currencyCode}
                  />
                </p>
              </div>
            ) : null*/}
            {line.customFields?.onetimeDiscount ? (
              <>
                <div className="w-full flex justify-between text-xs text-gray-500 dark:text-slate-300">
                  {line.customFields?.onetimeDiscount ? (
                    <>
                      <p>
                        <span className="font-bold dark:text-slate-400">
                          One time discount{' '}
                        </span>
                      </p>
                      <span
                        className={clsx({
                          'text-primary-500 dark:text-primary-400 cursor-pointer':
                            editable && onSubscriptionEdit,
                        })}
                        onClick={() => {
                          onSubscriptionEdit
                            ? onSubscriptionEdit(
                                'recurring',
                                'amounts',
                                'onetime-discount',
                              )
                            : undefined
                        }}
                      >
                        <Price
                          priceWithTax={-line.customFields?.onetimeDiscount}
                          currencyCode={currencyCode}
                          className="text-sm font-bold"
                        />
                      </span>
                    </>
                  ) : null}
                </div>
              </>
            ) : null}
          </SubscriptionRow>
        ) : null}

        {editable.length ? (
          <div className="flex divide-x *:px-2 first:*:pl-0 last:*:pr-0 divide-gray-200 dark:divide-slate-500">
            {subscriptionEditUrl &&
            hasSubscription(line) &&
            editable.find((s) =>
              ['dates', 'interval', 'amounts'].includes(s),
            ) ? (
              <Link
                to={subscriptionEditUrl}
                className="font-medium text-sm mt-1 mr-1 text-primary-600 hover:text-primary-500 dark:text-primary-400 dark:hover:text-primary-300"
              >
                Edit subscription
              </Link>
            ) : null}
            {groupEditUrl && hasGroup(line) && editable.includes('groups') ? (
              <Link
                to={groupEditUrl}
                className="font-medium text-sm mt-1 text-primary-600 hover:text-primary-500 dark:text-primary-400 dark:hover:text-primary-300"
              >
                Edit group
              </Link>
            ) : null}
          </div>
        ) : (
          <div />
        )}
        {agreementApproval && associatedAgreement ? (
          <div className="ml-1.5">
            <div className="flex justify-start items-center gap pt-1.5">              
              <p className="text-gray-600 dark:text-slate-400 mt-1 text-sm">
                I agree to the{' '}
                <TextButton
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault()
                    e.stopPropagation()
                    toggleModal(true)
                  }}
                >
                  terms of service
                </TextButton>{' '}
                for this student and class
                </p>
            </div>
          </div>
        ) : signedAgreement ? (
          <div className="dark:text-slate-400 ml-[4px] mt-0.5 text-sm">
            Agreed to the{' '}
            <TextButton
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault()
                e.stopPropagation()
                toggleModal(true)
              }}
            >
              terms of service
            </TextButton>{' '}
            for this student and class
          </div>
        ) : null}
        <div className="flex justify-between text-base font-medium mt-2 text-gray-900 dark:text-slate-300">
          <p></p>
          <p className="ml-4">
            <Price
              priceWithTax={linePrice}
              currencyCode={currencyCode}
              className="font-bold"
            ></Price>
          </p>
        </div>
      </div>
    </>
  )
}

export const CartItem: React.FC<{
  line: OrderLine
  currencyCode: CurrencyCode
  detailUrl?: string
  deleteUrl?: string
  onboardUrl?: string
  subscriptionEditUrl?: string
  groupEditUrl?: string
  defaultToOpen?: boolean
  editable?: EditType[]
  agreementApproval?: boolean
  compact?: boolean
  onSignAgreement?: (lineId: string, checked: boolean) => void
  onSubscriptionEdit?: (
    key: 'recurring' | 'registration',
    editType: EditType,
    displayType: DisplayType,
  ) => void
  className?: string
  priceIncludingTax?: boolean
  isStorefront?: boolean
  onAssignItemCustomer?: (lineId: string, customerId: string) => void
  onAdjustOrderLine?: (
    lineId: string,
    quantity: number,
    customFields?: OrderLineCustomFieldsInput,
  ) => void
  onClick?: (line: OrderLine) => void
  onRemove?: (lineId: string) => void
}> = ({
  line,
  currencyCode,
  detailUrl,
  deleteUrl,
  onboardUrl,
  subscriptionEditUrl,
  groupEditUrl,
  onSubscriptionEdit,
  defaultToOpen = true,
  isStorefront,
  editable = [],
  agreementApproval,
  compact,
  priceIncludingTax,
  className,
  onSignAgreement,
  onAssignItemCustomer,
  onAdjustOrderLine,
  onRemove,
  onClick,
}) => {
  const fetcher = useFetcher()
  const handleRemove = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      if (onRemove) {
        onRemove(line.id)
      }
    },
    [onRemove],
  )

  const handleClick = React.useCallback(
    (event: React.MouseEvent<any>) => {
      if (onClick) {
        onClick(line)
      }
    },
    [onClick],
  )

  const handleChangeQuantity = React.useCallback(
    (quantity: number) => {
      if (onAdjustOrderLine) {
        onAdjustOrderLine(line.id, quantity)
      } else {
        console.log('Missing adjustOrderLine handler')
      }
    },
    [onAdjustOrderLine],
  )

  const editableArr = React.useMemo(() => {
    if (!isStorefront) {
      return editable
    }
    let newEditable = [...editable]
    if (
      line.productVariant.facetValues.find(
        (f) => f.code === DISABLE_EDIT_FV_CODE && f.facet.code === SUBSCRIPTIONS,
      )
    ) {
      newEditable = newEditable.filter(
        (e) => !['amounts', 'interval', 'dates'].includes(e),
      )
    }
    if (
      line.productVariant.facetValues.find(
        (f) => f.code === DISABLE_EDIT_FV_CODE && f.facet.code === CLAZZ_FACET_CODE,
      )
    ) {
      newEditable = newEditable.filter((e) => e !== 'groups')
    }
    return newEditable
  }, [])

  const isSubscriptionSchedule = hasSubscription(line)
  const isGroup = line.customFields?.preferredGroup || hasGroup(line)
  const unitPrice = priceIncludingTax ? line.unitPriceWithTax : line.unitPrice
  const linePrice = priceIncludingTax ? line.linePriceWithTax : line.linePrice

  const name = line.productVariant.name
  const group = line.customFields?.preferredGroup
  let dateRange = getLineDateRange(line)

  return (
    <li
      key={line.id}
      className={clsx(
        'flex items-center p-1 py-3 hover:bg-gray-50 dark:hover:bg-slate-800',
        {
          'cursor-pointer': !!onClick,
        },
        className,
      )}
      onClick={handleClick}
    >
      <div className="self-start flex-shrink-0 rounded-md overflow-hidden">
        {line.featuredAsset?.preview ? (
          <img
            className="inline-block rounded-md h-14 w-14 md:h-20 md:w-20"
            src={line.featuredAsset?.preview}
            alt={line.productVariant.name.charAt(0)}
          />
        ) : (
          <div className="inline-flex p-3 items-center justify-center rounded-md h-14 w-14 md:h-20 md:w-20 bg-highlight-400 dark:bg-transparent">
            <p className="text-xl sm:text-2xl text-highlight-contrast-500 dark:text-slate-400">
              {line.productVariant.name.charAt(0).toUpperCase()}
            </p>
          </div>
        )}
      </div>
      <div className="ml-2.5 sm:ml-4 flex-1 flex flex-col self-stretch">
        <div className="flex justify-between text-base mb-0.5">
          <h3 className="justify-left leading-5 font-bold text-gray-900 dark:text-slate-300">
            {detailUrl ? (
              <Link
                to={detailUrl}
                className="hover:text-gray-600 dark:hover:text-slate-300"
                onClick={handleClick}
              >
                {line.productVariant.name}
              </Link>
            ) : (
              line.productVariant.name
            )}
          </h3>
        </div>
        {dateRange ? (
          <p className="text-sm whitespace-nobreak -mb-1">
            <span className="text-xs text-success-500">When: </span>
            <span className="text-xs font-medium text-xs text-gray-800 dark:text-slate-300">
              {dateRange}
            </span>
          </p>
        ) : null}
        {group ? (
          <div className="flex items-center text-sm -mb-1">
            <div className="flex justify-between text-sm">
              <p>
                <span className="text-xs text-success-500">
                  {group.label}:{' '}
                </span>
                <span className="font-medium text-xs text-gray-800 dark:text-slate-300">
                  {group.timeslots
                    ?.map(
                      (t) =>
                        `${t.dow}-${format(new Date(`2024-03-15T${t.time}`), 'h:mmaaa')}`,
                    )
                    .join(', ')}
                </span>
              </p>
            </div>
          </div>
        ) : null}
        {line.customFields?.assignedCustomer ? (
          <div className="flex items-center text-sm -mb-1">
            <div className="flex justify-between text-sm">
              <p>
                <span className="text-xs text-success-500 mr-1">
                  Assigned to:
                </span>
                <span className="font-medium text-xs text-gray-800 dark:text-slate-300">
                  {line.customFields?.assignedCustomer?.firstName +
                    ' ' +
                    line.customFields?.assignedCustomer?.lastName}
                </span>
              </p>
            </div>
            <div className="flex-1 text-right">
              {/* TODO isGroup is not a really proper check for onboard status. But it's ok here */}
              {/*!editable.length && onboardUrl && isGroup ? (
                <Link
                  to={`${onboardUrl}/${line?.customFields?.assignedCustomer?.id}`}
                  className="font-semibold text-md text-primary-600 hover:text-primary-500 dark:text-primary-400 dark:hover:text-primary-300"
                >
                  Onboard
                </Link>
              ) : null*/}
            </div>
          </div>
        ) : detailUrl ? (
          <Link
            to={detailUrl}
            className="text-xs text-error-500"
            onClick={handleClick}
          >
            Please assign a customer to this product
          </Link>
        ) : (
          <p className="text-xs text-error-500">
            Please assign a customer to this product
          </p>
        )}

        {isSubscriptionSchedule || isGroup ? (
          <Subscriptions
            line={line}
            currencyCode={currencyCode}
            subscriptionEditUrl={subscriptionEditUrl}
            groupEditUrl={groupEditUrl}
            priceIncludingTax={priceIncludingTax}
            onSubscriptionEdit={onSubscriptionEdit}
            editable={editableArr}
            displayDetails={!compact}
            defaultToOpen={defaultToOpen}
            agreementApproval={agreementApproval}
            onSignAgreement={onSignAgreement}
            onAdjustOrderLine={onAdjustOrderLine}
          />
        ) : (
          // no subscription
          <div className="h-full">
            <div className="flex justify-between text-base text-xs font-medium text-gray-500 dark:text-slate-400">
              <p>
                {line.quantity} x {name}
              </p>
              <p className="ml-4">
                <Price priceWithTax={unitPrice} currencyCode={currencyCode} />
              </p>
            </div>
            <div className="flex justify-between text-base mt-2 text-gray-900 dark:text-slate-300">
              {/* <CounterInput
                name="quantity"
                value={line.quantity}
                onChange={handleChangeQuantity}
              /> */}
              <p></p>
              <p className="ml-4">
                <Price
                  priceWithTax={linePrice}
                  currencyCode={currencyCode}
                  className="font-bold"
                />
              </p>
            </div>
          </div>
        )}
      </div>
      {editable.includes('remove') && !!onRemove && (
        <Button
          type="button"
          name="removeItem"
          value={line.id}
          variant="unstyled"
          className="font-medium p-2 text-gray-500 dark:text-slate-300"
          title="Remove Item"
          onClick={handleRemove}
        >
          <XCircleIcon className="w-8 h-8" />
        </Button>
      )}
      {editable.includes('remove') && !!deleteUrl && (
        <fetcher.Form action={deleteUrl} method="post">
          <input type="hidden" name="action" value="removeItem" />
          <input type="hidden" name="lineId" value={line.id} />
          <Button
            type="submit"
            variant="unstyled"
            className="font-medium p-2 text-gray-500 dark:text-slate-300"
            title="Remove Item"
            disabled={fetcher.state !== 'idle'}
          >
            <XCircleIcon className="w-8 h-8" />
          </Button>
        </fetcher.Form>
      )}
    </li>
  )
}
