import React, { useEffect, useState } from 'react'
import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table'
import clsx from 'clsx'

import { ProductVariant } from '~common/generated/admin-graphql'
import { GalleryListPropsType } from '~common/types'
import { Pagination } from '~common/components/table/Pagination'

const columns: ColumnDef<ProductVariant>[] = [
  {
    id: 'id',
    accessorKey: 'id',
    header: () => <span>ID</span>,
    footer: (props) => props.column.id,
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    id: 'name',
    accessorKey: 'name',
    header: () => <span>Name</span>,
    footer: (props) => props.column.id,
    enableColumnFilter: false,
    enableSorting: true,
  },
]

export const AllProductVariantsList: React.FC<
  GalleryListPropsType<ProductVariant>
> = ({
  items,
  itemCount,
  pageIndex = 0,
  onChangePageIndex,
  onChangePageSize,
  selected,
  setSelected,
}) => {
  const [pagination, setPagination] = useState<{
    pageIndex: number
    pageSize: number
  }>({
    pageIndex,
    pageSize: 12,
  })

  const table = useReactTable<ProductVariant>({
    data: items,
    columns,
    pageCount: Math.ceil(itemCount / pagination.pageSize),
    state: {
      pagination,
    },
    manualPagination: true,
    enableRowSelection: true,
    // Pipeline
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),

    onPaginationChange: setPagination,
    debugTable: false,
  })

  useEffect(() => {
    if (onChangePageIndex) {
      onChangePageIndex(pagination.pageIndex)
    }
  }, [pagination.pageIndex /*, onChangePageIndex*/])

  useEffect(() => {
    if (onChangePageSize) {
      onChangePageSize(pagination.pageSize)
    }
  }, [pagination.pageSize /*, onChangePageSize*/])

  const handleChangeIndex = React.useCallback(
    (idx: number) => {
      table.setPageIndex(idx)
      if (onChangePageIndex) {
        onChangePageIndex(idx)
      }
    },
    [onChangePageIndex],
  )

  const handleNext = React.useCallback(() => {
    table.nextPage()
    if (onChangePageIndex) {
      onChangePageIndex(pageIndex++)
    }
  }, [onChangePageIndex, pageIndex])

  const handlePrevious = React.useCallback(() => {
    table.previousPage()
    if (onChangePageIndex) {
      onChangePageIndex(pageIndex--)
    }
  }, [onChangePageIndex, pageIndex])
  return (
    <>
      <ul
        role="list"
        className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:grid-cols-6 xl:gap-x-8"
      >
        {items.map((item: ProductVariant) => {
          const isSelectedItem =
            selected?.findIndex(
              (variant: ProductVariant) => item.id == variant.id,
            )! > -1

          const asset = item.featuredAsset || item.product?.featuredAsset

          return (
            <li
              key={item.name}
              className="relative p-2"
              onClick={() => {
                let newSelected: ProductVariant[] = []
                if (!isSelectedItem) {
                  newSelected = selected ? [...selected, item] : []
                }
                if (setSelected) {
                  setSelected(newSelected)
                }
              }}
            >
              <div
                className={clsx(
                  'group w-40 p-2 rounded-sm sm:aspect-h-3 sm:aspect-w-2 bg-gray-100 hover:ring-2 hover:ring-indigo-500 hover:ring-offset-2',
                  isSelectedItem &&
                    'ring-2 ring-red-500 ring-offset-4 shadow-2xl',
                )}
              >
                <div className="w-full h-40">
                  <img
                    src={asset?.preview}
                    alt=""
                    className="w-40 h-40 inline-block pointer-events-none object-cover object-center group-hover:opacity-75"
                  />
                </div>

                <button
                  type="button"
                  className="absolute inset-0 focus:outline-none"
                >
                  <span className="sr-only">View details for {item.name}</span>
                </button>
                <p className="pointer-events-none mt-2 block text-sm font-medium text-gray-900 dark:text-slate-400">
                  {item.name}
                </p>
                <p className="pointer-events-none block text-xs font-medium text-gray-400 dark:text-slate-400">
                  {item.sku}
                </p>
              </div>
            </li>
          )
        })}
      </ul>
      <Pagination
        pageIndex={pagination.pageIndex}
        pageSize={pagination.pageSize}
        pageCount={Math.ceil(itemCount / pagination.pageSize)}
        itemCount={itemCount}
        hasNext={table.getCanNextPage()}
        hasPrevious={table.getCanPreviousPage()}
        onChangeIndex={handleChangeIndex}
        onNext={handleNext}
        onPrevious={handlePrevious}
      />
    </>
  )
}
